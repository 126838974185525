.wrapper {
  position: relative;
}

.backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  background-color: black;
  opacity: 0.7;
}
