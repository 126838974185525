@import '../../variables.less';
.App {
  .card-bulk-details {
      background: @whiteColor;
      border: 1px solid #F0F0F0;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
      border-radius: 4px;
      padding: 20px;
      .ant-form-item-label > label{
          font-family: 'Roboto';
          font-style: normal!important;
          font-weight: 400!important;
          font-size: 14px!important;
          line-height: 22px!important;
          color: @formItemLabel!important;
      }
      article.ant-typography{
        font-family: 'Roboto';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: @tableTextColor;
      }
  }
}
