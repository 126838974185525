@import "../../variables.less";

.modal-account {
  .ant-table {
    td {
      padding-top: 4px !important;
      padding-bottom: 4px !important;
    }
    p {
      color: @formItemLabel;
      margin: 0 !important;
    }
  }
  .search-button {
    svg {
      stroke: @whiteColor !important;
      fill: var(--ant-primary-color);
    }
  }
}

