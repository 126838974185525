@import "../../variables.less";

.App,
.ant-modal-root {
    .ant-table-content {
        margin-bottom: 15px;
    }

    .ant-table-thead {
        .ant-table-cell {
            background-color: var(--ant-primary-color);
            color: @textColor;
        }
    }

    .ant-table-tbody {
        .ant-table-row {
            font-weight: 400;
            font-size: 16px;
            text-align: left;

            .item-name {
                color: var(--ant-primary-color);
                text-align: left;
                padding: 0;
                margin: 0;

                span {
                    margin: 0 !important;
                    font-weight: 400;
                    font-size: 16px;
                }
            }

            .version {
                margin-top: -8px;

                .label {
                    font-size: 12px;
                    color: @formItemLabel;
                    font-weight: 400;
                    padding: 0;
                    margin: 0;
                }

                .value {
                    font-size: 12px;
                    color: @formItemLabel;
                    font-weight: 400;
                    padding: 0;
                    margin: 0;
                }
            }

            .ant-table-cell {
                color: @tableTextColor;
            }
        }

    }

    .ant-table-thead>tr>th,
    .ant-table-tbody>tr>td,
    .ant-table tfoot>tr>th,
    .ant-table tfoot>tr>td {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .ant-table>.ant-table-container>.ant-table-content>table>thead>tr>th,
    .ant-table>.ant-table-container>.ant-table-header>table>thead>tr>th,
    .ant-table>.ant-table-container>.ant-table-body>table>thead>tr>th,
    .ant-table>.ant-table-container>.ant-table-summary>table>thead>tr>th,
    .ant-table>.ant-table-container>.ant-table-content>table>tbody>tr>td,
    .ant-table>.ant-table-container>.ant-table-header>table>tbody>tr>td,
    .ant-table>.ant-table-container>.ant-table-body>table>tbody>tr>td,
    .ant-table>.ant-table-container>.ant-table-summary>table>tbody>tr>td,
    .ant-table>.ant-table-container>.ant-table-content>table>tfoot>tr>th,
    .ant-table>.ant-table-container>.ant-table-header>table>tfoot>tr>th,
    .ant-table>.ant-table-container>.ant-table-body>table>tfoot>tr>th,
    .ant-table>.ant-table-container>.ant-table-summary>table>tfoot>tr>th,
    .ant-table>.ant-table-container>.ant-table-content>table>tfoot>tr>td,
    .ant-table>.ant-table-container>.ant-table-header>table>tfoot>tr>td,
    .ant-table>.ant-table-container>.ant-table-body>table>tfoot>tr>td,
    .ant-table>.ant-table-container>.ant-table-summary>table>tfoot>tr>td {
        border-right: 1px solid @tableBorderColor;
    }

    .ant-table-row:nth-child(even) {
        background: @themeBC;
    }

    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-item {
        min-width: 26px;
        height: 26px;
        line-height: 24px;
    }

    .editable-table {
        background-color: @whiteColor;

        .disabled path {
            fill: #A1A1A1;
        }

        .editEnabled path {
            fill: var(--ant-primary-color);
        }

        .deleteEnabled path {
            fill: @deleteButtonColor;
        }

        .editEnabled,
        .deleteEnabled {
            width: 17px;
            height: 17px;
            position: absolute;
            top: -2px;
            left: -2px;
            zoom: 1.2;
        }
        .save path {
            fill: @saveButtonColor;
        }
        .save-cancel-group, .edit-delete-group {
            .ant-btn {
                border: none;
                padding: 0;
                width: 17px;
                height: 17px;
                background-color: inherit;
                box-shadow: none;
            }
        }
        .cancel path {
            stroke: @deleteButtonColor;
        }


        .addRecordButton path {
            stroke: var(--ant-primary-color);
            stroke-width: 1.5px;
        }

        .addRecordButton {
            background: @whiteColor;
            border: 1px solid var(--ant-primary-color);
            box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.04), 0px 2px 0px rgba(0, 0, 0, 0.04);
            border-radius: 2px;
        }

        .addRecordButton svg {
            width: 14px;
            height: 14px;
        }

        .addRecordButton span {
            color: var(--ant-primary-color);
            font-weight: 500;
            font-size: 16px;
        }
        .additional-row {
            margin-bottom: 15px;
        }
    }

    .list-only {
        .ant-table-wrapper {
            background-color: @whiteColor;
            padding: @pageBodyPadding;
        }

        .editable-table {
            .additional-row {
                margin: 15px 30px -15px;
                padding-top: 15px;
            }
        }
    }

}
