@import "../../../variables.less";

.avatar {
  cursor: pointer;
}

.menu-item-box {
  text-align: center;
  min-width: 100px;
}

.menu-item-box a {
  color: rgba(0, 0, 0, 0.85);
}

.menu-item-box a:hover {
  color: rgba(0, 0, 0, 0.85);
}

.divider {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.user-info {
  color: white;
  font-weight: bold;
}

.icon {
  margin-right: 6px;
  width: 20px;
}

.down-arrow path {
  stroke: @whiteColor;
  fill: var(--ant-primary-color);
}

