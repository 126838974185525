@import "../../variables.less";

.limit-detail {
    .form-item {
        padding-left: 6px;
        padding-right: 6px;
    }
    
    .form-label {
        color: @formItemLabel;
    }
}
