@import "../../variables.less";
.App{
  .btn-success {
      background-color: @successColor;
      border-color: @successColor;
      color: white;
  }

  .btn-success:hover {
    background-color: @successColor;
    border-color: var(--ant-primary-color);
  }
}

