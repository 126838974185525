@import "../../variables.less";

.App {
    .card-application-status-form {
        padding: 3%;
        // padding-left: 30px;
        .ant-form-item {
            margin-right: 1.96% !important;
        }

        .ant-form-item.input-field {
            width: 17.8% !important;
        }
        .ant-form-item.input-field input{
            font-size: 16px !important;
        }
        .ant-col label {
            margin-bottom: 0 !important;
            font-weight: 400 !important;
            color: @formItemLabel !important;
            font-size: 16px;
        }

        label.ant-form-item-required {
            position: relative;
        }

        .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
            position: absolute !important;
            right: -10px !important;
            top: 1px !important;
        }

        .input-field .ant-col label {
            font-size: 14px;
        }

        .checkbox-item {
            width: 10.7%;
        }
        .checkbox-item  span {
            font-size: 16px;
        }
        .checkbox-wraper {
            padding: 0 8px;
            display: inline-block;
        }

        .checkbox-wraper>div {
            display: flex;
            align-items: center;
        }

        .checkbox-wraper>div>span {
            margin-left: 10px;
        }

        .back-to-list {
          background: @whiteColor;
          border: 1px solid #F0F0F0;
          box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.04), 0px 2px 0px rgba(0, 0, 0, 0.04);
          border-radius: 4px;
          flex: none;
          order: 1;
          flex-grow: 0;
          font-weight: 500;
          color: @tableTextColor;
        }

        .create-save-button {
          box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.04), 0px 2px 0px rgba(0, 0, 0, 0.04);
          border-radius: 2px;
          flex: none;
          order: 0;
          flex-grow: 0;
          color: @whiteColor;
          font-weight: 500;
          font-size: 14px;
        }

        .back-to-list:hover {
          background: @whiteColor;
          border: 1px solid #F0F0F0;
          color: @tableTextColor;
        }
    }

}

