@import "../../variables.less";
@inputHeight: 44px;
@inputPadding: 10px 15px 10px 14px;
.App {
    .card-application {
        section {
            background: @whiteColor;
            border: 1px solid #FF4D4F;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
            border-radius: 4px;
            margin-bottom: 20px;
            padding: 22px 30px;
            label {
                font-weight: 400;
                font-size: 14px;
                color: @formItemLabel;
            }
            // .ant-form-item-control-input-content {
                
            // }

            .ant-form-item-control-input-content {
                .ant-select-selector,  input.ant-input,  .ant-picker {
                    padding: @inputPadding;
                    background: @whiteColor;
                    box-sizing: border-box;
                    height: @inputHeight;
                    width: 100%;
                    display: block;
                }
                input.ant-select-selection-search-input {
                    height: 100%;
                }
                span, input {
                    font-weight: 400;
                    font-size: 16px;
                    color: @tableTextColor;
                }
                span.anticon-down, span.anticon-search {
                    svg {
                        fill: @tabTextColor;
                    }
                }
            }
            .ant-form-item {
                margin-bottom: 10px;
            }
        }
        section.account-info, section.card-info {
            .header {
                display: flex;
                flex-flow: row;
                justify-content: space-between;
            }
            .ant-form-item-row {
                flex-flow: column;
            }

            .ant-form-item-control-input-content span.account-value {
                padding: @inputPadding;
                background: @whiteColor;
                border: 1px solid #D9D9D9;
                border-radius: 2px;
                box-sizing: border-box;
                height: @inputHeight;
                width: 100%;
                display: block;
                background-color: @themeBC;
            }

            .account-row {
                flex-flow: row;
                justify-content: space-between;
                .account-col {
                    flex-grow: 1;
                    width: 30%;
                    .card-holder-name {
                        display: flex;
                        flex-flow: row;
                    }
                }
                .account-col-space {
                    flex-grow: 1;
                    width: 5%;
                }
                .account-col-large {
                    flex-grow: 1;
                    width: 65%;
                }
            }
        }
        section.action-info {
            pre {
                margin-bottom: 0;
            }
        }
        .ant-page-header .ant-divider-horizontal {
            margin: 18px 0;
        }
        .ant-divider-horizontal {
            margin: 16px 0;
        }
        h5.ant-typography {
            font-weight: 500;
            font-size: 20px;
            color: @tableTextColor;
        }

    }
}

