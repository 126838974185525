@import '../../variables.less';
.App {
    .ant-collapse-header-text  svg{
        margin-bottom: -4px;
    }
    .ant-page-header{
        background-color: @themeBC !important ;
    }
    .ant-page-header-heading-title{
        font-weight: 500;
    }
    .ant-collapse-content-box{
        padding: 10px 0px !important;
    }
    .form-wrapper{
        display: flex;
        column-gap: 20px;
        .ant-form-item{
            flex: 1;
        }
        .ant-select-selector {
            height: 36px !important;
        }
        .ant-select-selection-placeholder {
            line-height: 34px !important;
        }

        .ant-input {
            height: 36px !important;
        }
        .ant-picker {
            height: 36px !important;
        }
        .ant-form-item-label label{
            font-weight: 400 !important;
            color:@formItemLabel ;            
        }
    }
    .btn-wrapper {
        display: flex;
        column-gap: 20px;
        justify-content: flex-end;
        button{
            width: 10% !important;
            span{
                margin: 0px !important;
            }
        }
    }
    .btn-wrapper > button:first-child > span {
        color: var(--ant-primary-color) ;
    }
    .btn-wrapper > button:first-child  {
        border:1px solid var(--ant-primary-color) ;
    }
    .ant-collapse-header{
        border-bottom: 1px solid #DBDBDB !important;
    }
    .ant-collapse-content{
        background-color: @whiteColor !important;
    }

    // card application queue
    .card-application-queue {
        .search-body {
            .ant-collapse {
                padding: 30px 30px 0px 30px;
                background-color: @whiteColor;
                .ant-collapse-header {
                    background-color: @formItemBG;
                }
            }
        }
    }
}

