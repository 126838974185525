@import "../../variables.less";

.ant-modal-root {
  .comparison-box {
    .diffmod , .diffdel{

      text-decoration: none !important;
      color: @tableTextColor;
    }
    
    .ant-modal-body {
      padding-top: 0px !important;
    }

    .ant-model-content {
      box-sizing: border-box;
      background: @whiteColor;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
      border-radius: 4px;
    }

    .ant-row {
      margin: 0px;
      height: 50px;
      line-height: 50px;
    }

    .ant-col {
      border-right: 1px solid #F0F0F0;
      border-bottom: 1px solid #F0F0F0;
    }

    .ant-row .ant-col:first-child {
      border-left: 1px solid #F0F0F0;
    }

    .ant-collapse {
      margin-top: 25px !important;

      .ant-collapse-header {
        background-color: var(--ant-primary-color);

        .ant-collapse-header-text h5 {
          color: white !important;
          font-weight: 500 !important;
        }

      }
      .ant-row:first-child .ant-col {
        border-top: 1px solid #F0F0F0;
      }

    }

    .checkbox-ins-container {
      label {
        background: none;
        padding-left: 5px;
        span {
          outline: 5px solid @comparationGreen;
        }
      }
    }

    .checkbox-del-container {
      label {
        background: none;
        padding-left: 5px;
        span {
          outline: 5px solid @comparationRed;
        }
      }
    }

    .header-row {
      background-color: var(--ant-primary-color);
      color: white;
    }

    ins, .ins {
      background-color: @comparationGreen;
    }
    
    del, .del {

      background-color:@comparationRed;
    }
    
    chg {
      background-color:yellow;
    }
  }

  .ant-modal-header {
    background-color: white !important;
    border-bottom: none !important;
    padding-bottom: 0px !important;
    padding-top: 25px !important;
  }
  .ant-modal-title {
    color: #000 !important;
    font-weight: 500 !important;
    font-size: 24px;
  }

}
