@import "../../variables.less";

.ant-modal-root {

    .card-product-history-detail {

        .ant-form-item-control-input-content span:last-child {
            font-weight: 700;
            font-size: 16px;
        }

        .ant-form-item-control-input-content span:first-child {
            font-weight: 400;
            font-size: 14px;
        }
    }
    .ant-modal-close {
        top: 10px;
        right: 10px;

        span {
            text-align: right;
        }

    }
}
