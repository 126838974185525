// Notification
.custom-class {
    .ant-notification-notice-message {
        // font-style: normal;
        font-weight: 500;
        // font-size: 24px;
        font-size: 20px;
        // line-height: 28px;
    }

    .ant-notification-notice-icon svg {
        height: 40px;
        width: 40px;
    }

    .ant-notification-notice-message,
    .ant-notification-notice-description {
        margin-left: 64px;
    }

    .ant-notification-notice-description {
        font-weight: 400;
        font-size: 16px;
        color: #727272;
    }

}
