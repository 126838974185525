.App {
    .card-product-diff {
        border: 1px red solid;
    }
    
    .card-product-active-version-title {
        font-weight: bold;
    }
    .card-product-pending-version-title {
        font-weight: bold;
    }
    
    .divider {
        margin-bottom: 20px;
    }
    
    .buttons {
        display: flex;
        justify-content: center;
    }
    
    .custom-input-error {
        border: 1px solid #ff4d4f;
        background: #fff;
    }
}
