@import "../../variables.less";

.fee-detail {
    .form-item {
        padding-left: 6px;
        padding-right: 6px;
    }

    .form-label {
        color: @formItemLabel;
    }

    // .additional-row {
    //   margin-right: 0px!important;
    // }
}

.pending-detail-body {
    .ant-row {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}
