// @import '~antd/dist/antd.less';
// @import '~antd/lib/style/themes/dark.less';
@import '~antd/dist/antd.less';
@import "./variables.less";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;600;700&display=swap');

.App, .ant-modal-root {

  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-btn:focus, .ant-input-focused, 
  .ant-input:focus,
  .ant-input-affix-wrapper:focus,
  .ant-switch:focus,
  .ant-input-number-focused,
  .ant-input-number:focus,
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-pagination-item:focus-visible,
  .ant-pagination-options-quick-jumper input:focus,
  .ant-pagination-options-quick-jumper input-focused,
  .ant-picker-focused,
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    // border-color: var(--ant-primary-color-hover);
    border: solid 1px @focusColor !important;
    // box-shadow: 0 0 0 2px @colorOutline;
    box-shadow: 0 0 0 4px @colorOutline !important;
    // border-right-width: 1px;
    outline: 0;
  }

  
  
  // .ant-select-selector:focus,
  // .ant-select-selector:active,
  // .ant-select-open .ant-select-selector,
  // .ant-checkbox-input:focus,
  // .ant-input-affix-wrapper:focus,
  // .ant-checkbox-input:focus+.ant-checkbox-inner,
  // .ant-btn:focus 
  // {
    
  // }

  font-family: 'Roboto', sans-serif;
  .ant-page-header {
    background-color: inherit;
  }

  // .ant-select-selector {
  //   height: 26px !important;
  // }
  // .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  //   line-height: 24px;
  // }

  .ant-page-header {
    .ant-page-header-heading-extra {
      div.ant-space {
        gap: 14px !important;
      }
    }
  }

  .ant-layout-sider-children {
    background-color: @whiteColor;
  }

  .ant-layout-header {
    background-color: var(--ant-primary-color);
    padding: 0;
  }

  .ant-layout-header span, h3, p {
    color: @textColor;
  }

  .ant-layout-sider-trigger {
    background-color: var(--ant-primary-color);
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: @selectedTabTextColor;
  }

  .ant-tabs-tab-btn {
    font-weight: 500;
    color: @tabTextColor;
  }

  // card list
  .card-list {
      .card-search-body {
        .ant-collapse, .ant-collapse-item {
          border: none;
        }
        .ant-collapse {
          padding: 30px 30px 0px 30px;
          background-color: @whiteColor;
          .ant-collapse-content-box {
            padding-left: 0;
            padding-right: 0;
          }
          .buttons {
            font-weight: 500;
            font-size: 16px;
            .ant-col {
              display: flex;
              justify-content: flex-end;
            }
            .clear-filters {
              background: @whiteColor;
              border: 1px solid var(--ant-primary-color);
              border-radius: 2px;
              color: var(--ant-primary-color);
            }
            button {
              width: 100%;
              justify-content: center;
              span {
                margin-left: 0 !important;
              }
            }
            .submit {
              background: var(--ant-primary-color);
              border-radius: 2px;
              color: @whiteColor;
            }
          }
          .ant-collapse-header {
            display: flex;
            flex-flow: row-reverse;
            .ant-collapse-arrow {
              color: @formItemLabel;
              font-size: 16px;
            }
            .header-text {
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: #5B5B5B;
            }
            .header-icon svg {
              margin-left: 5px;
              fill: #5B5B5B;
            }
          }
        }
        .card-search-panel {
          background-color: @searchPanelBC;
          .ant-form-item-label {
            padding-bottom: 2px;
          }
          .ant-divider-horizontal {
            margin: 18px 0;
          }
          .ant-form-item {
            margin-bottom: 12px;
            .ant-picker-range {
              width: 100%;
            }
          }
          label {
            font-weight: 400;
            font-size: 14px;
            color: @formItemLabel;
          }
        }
      }
      .ant-page-header-heading-title {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        color: @pageHeaderTitleColor;
      }
      .ant-page-header-heading-extra .operation-button {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: @pageHeaderTitleColor;
      }
  }
  .ant-form-item-required::before {
    position: absolute;
    right: -12px;
  }

  .ant-table-thead .ant-table-cell:hover {
    background-color: @themeColorHover;
  }

  .ant-form-item-label {
    padding-bottom: 0;
  }

  .ant-form-item-label label {
    font-weight: 400;
    color: @formItemLabel;
  }

  .ant-menu-item-selected {
    color: var(--ant-primary-color);
    background-color: @selectedMenuItemBC !important;
  }

  .ant-menu-item-selected::before {
    content: "";
    border-left: 3px solid var(--ant-primary-color);
    position: absolute;
    left: 0px;
    width: 1px;
    height: 100%;
  }

  .ant-menu-item-selected::after{
    border-right: none !important;
  }

  .ant-picker-calendar {
    .ant-picker-body {
      padding: 0;
      .ant-picker-content {
        thead {
          background-color: #F2F2F2;
          height: 24px;
          th {
            text-align: center;
            font-weight: 500;
          }
        }
        .ant-picker-calendar-date {
          border-radius: 12px;
        }
        .ant-picker-calendar-date-today::before {
          border: none;
        }
      }
    }
    
  }
  h5 {
    font-weight: 500;
    font-size: 20px;
  }
  .ant-checkbox-wrapper {
    span {
      font-weight: 400;
      color: @formItemLabel;
    }
  }
  .btn-edit {
      background-color: @editBC;
      border-color: @editBC,
  }
}

.ant-modal-root {
  .ant-modal-header {
    background-color: var(--ant-primary-color);
    .ant-modal-title {
      color: @textColor;
    }
  }
  .ant-modal-close {
    svg {
      color: @textColor;
    }
  }
  .ant-page-header-heading-title{
    font-weight: 500 !important;
  }
  .ant-modal-body{
    padding: @pageBodyPadding;
  }
}


.pending-detail-body {
  background-color: @whiteColor;
  padding: @pageBodyPadding;

  .card-product-label, .pending-comparison .pending-label {
    text-align: left;
    
    padding: 8px 0px;
    margin-left: -16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: @tableTextColor;
  }


  
  .card-product-active-version, .card-product-pending-version.no-change, .pending-comparison div.current, .pending-comparison div.no-change, .ant-alert {
        padding: 8px 0px !important;
  }
  .ant-alert {
    margin-right: -16px;
  }
  
}

