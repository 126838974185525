@import '../../variables.less';
.header {
  width: 100%;
  padding: 0;
  position: fixed;
  z-index: 1000;
  right: 0;
  display: flex;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 320px;
  h3 {
    color: @whiteColor !important;
  }
}

.sider {
  overflow: auto;
  overflow-x: hidden;
  height: calc(100vh - 64px - 48px - 36px);
  position: fixed !important;
  left: 0;
  top: 64px;
  background-color: @whiteColor !important;
  margin-bottom: 48px;
}

.menu {
  padding-bottom: 24px !important;
}

.sider-item {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.credit {
  text-align: center;
  position: fixed;
  width: 320px;
  left: 0;
  bottom: 48px;
  color: #939393;
  font-size: 13px;
  z-index: 1000;
  background-color: @whiteColor;
  padding: 8px 0;
}

.body {
  padding-top: 64px;
  display: flex;
  flex-direction: column;
}

.data-content {
  background-color: @themeBC;
  padding: 16px 32px;
  overflow: "initial";
  height: 100%;
  min-height: calc(100vh - 64px) !important;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.content-minimized {
  margin-left: 320px;
}

.content-maximized {
  margin-left: 64px;
}

