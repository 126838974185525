@import '../../variables.less';
@height: 44px;
@buttonBC: #F6F6F6;
.App {
    .import-card-references {
        .page-body {
            background-color: @whiteColor;
            border: 1px solid #F0F0F0;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
            border-radius: 4px;
            padding: @pageBodyPadding;
            .data-area {
                display: flex;
                flex-flow: row;
                justify-content: space-between;
                font-weight: 400;
                font-size: 16px;
                color: @tableTextColor;
                // .file-upload {
                    .upload-element, .input-block {
                        margin-bottom: 20px;
                        .ant-space-item {
                            margin-top: 8px;
                        }
                        input {
                            height: @height;
                        }
                        .ant-btn {
                            gap: 10px;
                            border-radius: 2px;
                            font-weight: 500;
                            font-size: 16px;
                            height: @height;
                        }
                    }
                // }
                .file-upload, .manual-input {
                    width: 50%;
                }
                .manual-input {
                    .add-manual, .input-block, .input-element {
                        display: flex;
                        flex-direction: row-reverse;
                    }
                    .input-element input {
                        width: 250px;
                    }
                }
                .ant-upload, .read-data, .add-manual  {
                    .ant-btn {
                        gap: 10px;
                        border-radius: 2px;
                        font-weight: 500;
                        font-size: 16px;
                        height: @height;
                    }
                    .ant-btn:not([disabled]) {
                        background-color: @buttonBC;
                        // padding: 13px 47px;
                        border: 1px solid #D9D9D9;
                        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
                        color: #011528;
                    }
                    > .ant-btn:not([disabled]) {
                        background-color: @buttonBC;
                        // padding: 13px 47px;
                        border: 1px solid #D9D9D9;
                        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
                        color: #011528;
                    }
                }

                .ant-upload-list-item-name {
                    font-weight: 400;
                    font-size: 16px;
                    color: @tableTextColor;
                }
            }

            .target-action {
                font-weight: 400;
                font-size: 16px;
                color: @tableTextColor;
                display: flex;
                gap: 8px !important;
                .ant-select-selector,  input.ant-input,  .ant-picker {
                    padding: 10px 15px 10px 14px;
                    background: @whiteColor;
                    box-sizing: border-box;
                    height: @height;
                    width: 100%;
                    display: block;
                }
                input.ant-select-selection-search-input {
                    height: 100%;
                }

                .ant-select-single:not(.ant-select-customize-input) .ant-select-selector::after {
                    line-height: 0px;
                }
                .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
                    line-height: 0px;
                }
            }

            .ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active {
                svg path {
                    fill: rgba(0, 0, 0, 0.25);
                }
            }
        }
        .ant-page-header {  
            .import-another[disabled] {
                svg path {
                    stroke: rgba(0, 0, 0, 0.25);
                }
            }
        }
    }
}
