.talkbubble {
  width: 120px;
  height: 60px;
  background: greenyellow;
  position: relative;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.talkbubble:before {
  content: "";
  position: absolute;
  left: 100%;
  top: 20px;
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-left: 26px solid greenyellow;
  border-bottom: 13px solid transparent;
}
