.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.login-form {
  max-width: 400px;
}

.input-group {
  margin-bottom: 36px;
}

.button {
  width: 100%;
  margin-bottom: 12px;
}
