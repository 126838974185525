@import '../../variables.less';
.App {
    .card-product-form {
        padding: 30px;
        .ant-select-selector {
            height: 45px !important;
        }

        .ant-select-selection-placeholder {
            line-height: 43px !important;
        }

        .ant-input {
            height: 45px !important;
        }

        .ant-select-selection-item {
            line-height: 43px !important;
        }

        label.ant-form-item-required {
            position: relative;
            font-weight: 400 !important;
            color: @formItemLabel !important
        }

        .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
            position: absolute !important;
            right: -10px !important;
            top: 1px !important;
        }
        .ant-select-selection-search .ant-select-selection-search-input{
            height: 100% !important;
        }
    }

    .ant-btn span {
        margin: 0 0 0 8px !important;
    }

}
