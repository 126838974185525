@import '../../variables.less';
.App {
  .card-scheduler-form {
    h5{
        /* H5-Headline */
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: @tableTextColor;
    }
    .title-divider{
      width: 100%;
      margin-top: 16px;
      margin-bottom: 16px;
    }

    label.ant-form-item-required, .ant-form-item-label>label {
      position: relative;
      font-weight: 400 !important;
      color: @formItemLabel !important
    }

    .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      position: absolute !important;
      right: -10px !important;
      top: 1px !important;
    }
  }
}
